
.field__controls {
  position: relative;
}

.field__controls-spinner {
  position: absolute;
  top: 0;
  inset-inline-end: -2.1em;
}
